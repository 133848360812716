import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Physics2DPlugin } from 'gsap/Physics2DPlugin'
import { SplitText } from 'gsap/SplitText'
import { useGSAP } from '@gsap/react'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'
import { getSteamFromConfig, getSteamToConfig, mmConfig } from '/util/gsapHelpers'

import { images } from '/images'

import styles from './Spotlight.css'

gsap.registerPlugin(ScrollTrigger, Physics2DPlugin, SplitText)

export function Spotlight() {
  const componentRef = React.useRef()
  const headingRef = React.useRef()

  useGSAP(() => {
    const mm = gsap.matchMedia()
    mm.add(mmConfig, (ctx) => {
      const { isMobile } = ctx.conditions
      const splitHeading = new SplitText(headingRef.current, { types: 'words' })

      const tlHeading = gsap.timeline()
        .fromTo(
          splitHeading.words,
          getSteamFromConfig(isMobile),
          getSteamToConfig({ each: 0.05 })
        )

      ScrollTrigger.create({
        animation: tlHeading,
        trigger: componentRef.current,
        start: 'top 50%',
        toggleActions: 'play none none none'
      })
    })
  })

  return (
    <div ref={componentRef} className={cx(styles.component, 'spotlight-container')}>
      <div className={styles.layout}>
        <h2 ref={headingRef} className={styles.heading}>Serveer tenslotte met de onverdeelde aandacht en liefde van onze <button data-x='click-to-release-hearts' className={styles.button} onClick={handleTracking}> <span className={styles.inner}>4.676</span><Hearts layoutClassName={styles.heartsLayout} /> <span className={styles.underline}> <svg className={styles.line} width="300%" height="100%" viewBox="0 0 1200 60" preserveAspectRatio="none"> <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0" /> </svg> </span> </button> collega's. </h2>
      </div>
      <div className={styles.overlap} />
    </div>
  )
  function handleTracking() {
    trackInteraction({
      label: 'spotlight-hearts',
      type: 'click',
      action: 'clicked'
    })
  }
}

function Hearts({ layoutClassName }) {
  const componentRef = React.useRef(null)
  const { peopleImages } = images
  const burstTimeoutRef = React.useRef(null)

  const { contextSafe } = useGSAP(
    () => {
      const tlHeartsFloating = gsap.timeline({ repeat: -1 })

      tlHeartsFloating
        .fromTo('[data-heart-shape]',
          {
            rotation: 0,
            scale: 0
          },
          {
            rotation: 'random(-60, 60)',
            scale: 'random(1.3, 2)',
            duration: 4,
            stagger: 0.6,
            physics2D: {
              velocity: 'random(100, 350)',
              angle: 'random(-60, -40)',
              acceleration: 'random(50, 100)',
              accelerationAngle: 180,
            },
            keyframes: [
              { opacity: 1 },
              { opacity: 1, duration: '95%' },
              { opacity: 0 }
            ],
          }
        )

      ScrollTrigger.create({
        trigger: '.spotlight-container',
        start: 'top 50%',
        animation: tlHeartsFloating,
        toggleActions: 'play reset play play',
      })

      const startBurstHandler = contextSafe(() => {
        addBurstHeart()
        tlHeartsFloating.repeat(0)
        disableUserSelect()
      })

      const stopBurstHandler = contextSafe(() => {
        clearTimeout(burstTimeoutRef.current)
        tlHeartsFloating.restart()
        enableUserSelect()
      })

      function getRandomImageSrc() {
        return peopleImages[Math.floor(peopleImages.length * Math.random())]
      }

      function addBurstHeart() {
        clearTimeout(burstTimeoutRef.current)
        const element = document.createElement('div')
        element.classList.add(styles.heartShape)
        const img = document.createElement('img')
        img.classList.add(styles.image)
        img.src = getRandomImageSrc()
        element.appendChild(img)
        componentRef.current.appendChild(element)

        gsap.timeline({
          onComplete: () => { element.remove() }
        })
          .fromTo(element,
            {
              rotation: 0,
              scale: 0,
            },
            {
              rotation: 'random(-60, 60)',
              duration: 2,
              scale: 1.6,
              physics2D: {
                velocity: 'random(200, 550)',
                angle: 'random(-135, -45)',
                gravity: 50,
              },
            })
          .to(element, { opacity: 0, scale: 2.5, duration: 0.3, ease: 'power3.out' }, '-=1')

        const delay = Math.floor(50 * Math.random()) + 50
        burstTimeoutRef.current = setTimeout(addBurstHeart, delay)
      }

      componentRef.current.addEventListener('mousedown', startBurstHandler)
      componentRef.current.addEventListener('mouseup', stopBurstHandler)
      componentRef.current.addEventListener('touchstart', startBurstHandler, { passive: true })
      componentRef.current.addEventListener('touchmove', stopBurstHandler, { passive: true })
      componentRef.current.addEventListener('touchend', stopBurstHandler)

      function disableUserSelect() {
        // user-select: none is set on link, but
        // when holding down finger on Safari mobile
        // it selects the next text node
        // temporarily set user-select: none on body
        // to prevent that
        document.body.classList.add(styles.noUserSelect)
      }

      function enableUserSelect() {
        document.body.classList.remove(styles.noUserSelect)
      }

      return () => {
        componentRef.current.removeEventListener('mousedown', startBurstHandler)
        componentRef.current.removeEventListener('mouseup', stopBurstHandler)
        componentRef.current.removeEventListener('touchstart', startBurstHandler, { passive: true })
        componentRef.current.removeEventListener('touchmove', stopBurstHandler, { passive: true })
        componentRef.current.removeEventListener('touchend', stopBurstHandler)
      }

    }, { scope: componentRef })

  return (
    <div className={cx(styles.componentHearts, layoutClassName )} ref={componentRef}>
      {peopleImages.map((image, index) => (
        <div
          key={index}
          className={styles.heartShape}
          data-heart-shape
        >
          <img className={styles.image} src={image} alt='' />
        </div>
      ))}
    </div>
  )
}
